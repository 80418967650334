<!-- @format -->

<template>
  <div class="bg-white mt-8 pb-8 flex-1 justify-center shadow-sm rounded-lg">
    <div class="px-10 py-16 flex justify-between">
      <div>
        <div class="font-medium">
          <span class="text-4xl">{{
            props.data.totalResolvedComplaintsCount
          }}</span>
          <span class="text-gray-400"
            >/ {{ props.data?.totalComplaintCount }}</span
          >
        </div>
        <div class="text-gray-400 font-medium">Overall Complaints</div>
      </div>

      <ComplaintsDurationFilter @at-filter-changed="emitFilterChange" />
    </div>
    <div class="border-2 border-gray-200 mb-16"></div>
    <div class="overflow-auto">
      <StackBarChart :data="props.data" :key="props.data" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import ComplaintsDurationFilter from "@/components/newUi/atoms/ComplaintsDurationFilter.vue";
import StackBarChart from "@/components/newUi/charts/StackBarChart.vue";

const props = defineProps({
  data: {
    type: Array,
  },
});

const emit = defineEmits(["at-filter-change"]);

const emitFilterChange = (filter) => {
  emit("at-filter-change", filter);
};
</script>

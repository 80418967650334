<!-- @format -->

<template>
  <div>
    <canvas :id="chartId" class="px-10"></canvas>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, computed } from "vue";
import { Chart } from "chart.js";

const props = defineProps({
  data: {
    type: Array,
  },
});

const labels = computed(() => {
  return (
    props.data?.data?.map(
      (practice) => practice?.practice_name?.slice(0, 5) + ".."
    ) || []
  );
});

const resolvedComplaints = computed(() => {
  return (
    props.data?.data?.map((practice) => practice?.resolved_complaints_count) ||
    []
  );
});

const totalComplaints = computed(() => {
  return (
    props.data?.data?.map((practice) => practice?.total_complaints_count) || []
  );
});

const chartData = {
  labels: labels.value,
  datasets: [
    {
      label: "Resolved Complaints",
      data: resolvedComplaints.value,
      backgroundColor: "rgba(113, 214, 206, 1)",
      stack: "Stack 0",
    },
    {
      label: "Total Complaints",
      data: totalComplaints.value,
      backgroundColor: "rgba(234, 234, 234, 1)",
      stack: "Stack 0",
    },
  ],
};

const titlePluginOptions = {
  display: true,
  text: "",
  color: "#686868",
  font: {
    size: 14,
    weight: 700,
  },
};

const config = {
  type: "bar",
  data: chartData,
  options: {
    responsive: true,

    aspectRatio: 14 / 3,
    plugins: {
      title: titlePluginOptions,
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          return tooltipTitle(tooltipItem, data);
        },
      },
    },
    legend: {
      display: true,
      position: "bottom",
      align: "start",
    },

    scales: {
      xAxes: [{ stacked: true }],
      yAxes: [
        {
          stacked: false,
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

const chartObj = ref(null);
const chartId = `chart-${Math.random().toString(36).substr(2, 9)}`;
const tooltipTitle = (tooltipItem) => {
  return props.data?.data[tooltipItem[0].index]?.practice_name;
};
onMounted(() => {
  chartObj.value = new Chart(document.getElementById(chartId), config);
});
</script>
